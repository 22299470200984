import styled from 'styled-components';
import { CustomText } from '@components/ui';

export const Container = styled.div.attrs({
  className: 'h-5 absolute z-10 bottom-0 right-0 w-full flex justify-between items-center',
})`
  box-sizing: border-box;
  padding: 0px 10px;
  height: 22px;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0.64;
    z-index: 1;
  }
`;

export const TimerText = styled(CustomText).attrs({
  className: 'relative',
  size: 11,
  weight: 400,
  color: 'white',
})`
  z-index: 2;
`;
