import React, { useContext, useState } from 'react';
import { CustomImage, CustomLink } from '@components/ui';
import { TActionName } from '../type';
import { CommonContext } from '../../../../src/provider';
import { CardScrap, ImageWrapper, MainImage, Skeleton, SubImage } from './styled';

interface CardImageInfo {
  mainImageURL: string;
  subImageURL: string;
  href: string;
  title: string;
  goodsNo: string;
  wishSno: string;
  isDisplayOnScrap: boolean;
  isScrapEdit: boolean;
  isDisabled?: boolean;
  onSale?: boolean;
  badgeNames?: string[];
  onClick?: (actionName: TActionName) => void;
}

export const CardImg: React.FC<CardImageInfo> = ({
  mainImageURL,
  subImageURL,
  href,
  title,
  goodsNo,
  wishSno,
  isDisplayOnScrap,
  isScrapEdit,
  onSale = true,
  badgeNames,
  onClick,
}) => {
  const { gridWidth } = useContext(CommonContext);
  // 이미지 스켈레톤 여부
  const [isSkelton, setIsSkelton] = useState(true);

  const handleImageWrapperClick = () => onClick('image');

  // Card 이미지 로드 된 후 스켈레톤 UI 제거
  const handleLoad = () => {
    // 스켈레톤 UI 제거
    setIsSkelton(false);
  };

  return (
    <ImageWrapper isDisplayOnScrap={isDisplayOnScrap} isScrapEdit={isScrapEdit} onClick={handleImageWrapperClick} className={'imageWrapperGrid'}>
      {isScrapEdit ? (
        <>
          <MainImage>
            <CustomImage src={mainImageURL} width={gridWidth} height={200} backgroundColor={'white'} alt={title} onLoad={handleLoad} sizes={gridWidth + 'px'} />
          </MainImage>
          <SubImage>
            <CustomImage src={subImageURL} width={gridWidth} height={200} backgroundColor={'white'} alt={title} sizes={gridWidth + 'px'} />
          </SubImage>
        </>
      ) : (
        <CustomLink href={href} newWindow>
          <MainImage>
            <CustomImage src={mainImageURL} width={gridWidth} height={200} backgroundColor={'white'} alt={title} onLoad={handleLoad} sizes={gridWidth + 'px'} />
          </MainImage>
          <SubImage>
            <CustomImage src={subImageURL} width={gridWidth} height={200} backgroundColor={'white'} alt={title} sizes={gridWidth + 'px'} />
          </SubImage>
        </CustomLink>
      )}
      {!isDisplayOnScrap && !isScrapEdit && <CardScrap isDisabled={!onSale} wishSno={wishSno} goodsNo={goodsNo} legacy={true} badgeNames={badgeNames} />}
      {isSkelton && <Skeleton />}
    </ImageWrapper>
  );
};
